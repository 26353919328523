import React, { Children, memo, useCallback, useEffect, useRef, useMemo, useState } from 'react'
import { useTransition, animated } from 'react-spring'

const Slider = memo(({ children }) => {
    const [slideIndex, setSlideIndex] = useState(0)
    const [slideTransition, setSlideTransition] = useState(true)
    const interval = useRef()
    const childrenArray = useMemo(() => Children.toArray(children), [children])

    const transitions = useTransition(
        slideIndex,
        (key) => key,
        {
            from: {
                opacity: 0,
                transform: 'translate3d(100%, 0, 0)',
            },
            enter: {
                opacity: 1,
                transform: 'translate3d(0%, 0, 0)',
            },
            leave: {
                opacity: 0,
                transform: 'translate3d(-50%, 0, 0)',
            },
        },
    )

    const handleClick = useCallback(() => setSlideIndex((state) => (state + 1) % 3), [])

    useEffect(() => {
        if (childrenArray.length > 1) {
            if (!slideTransition) {
                clearInterval(interval.current)
            }

            interval.current = setInterval(() => {
                if (slideTransition) {
                    handleClick()
                }
            }, 3000)
        }
    }, [interval.current, slideTransition, childrenArray])

    useEffect(() => {
        return () => {
            if (interval.current) {
                clearInterval(interval.current)
            }
        }
    }, [interval.current])

    const handleSlideIndexChange = useCallback((index) => (event) => {
        event.stopPropagation()
        setSlideIndex(index)
    }, [])

    const handleMouseEnter = useCallback(() => setSlideTransition(false), [])

    const handleMouseLeave = useCallback(() => setSlideTransition(true), [])

    return (
        <div
            className={`w-full h-full ${childrenArray.length > 1 ? 'cursor-pointer' : ''}`}
            onClick={childrenArray.length > 1 ? handleClick : undefined}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {transitions.map(({ item, props, key }) => (
                <animated.div key={key} className="w-full h-full absolute top-0 left-0 p-10" style={props} tabIndex={0} role='button'>
                    {childrenArray[item]}
                </animated.div>
            ))}
            {childrenArray.length > 1 && (
                <div className="flex justify-center mt-4 absolute bottom-4 left-0 right-0" tabIndex={0} role="button">
                    {childrenArray.map((_, index) => (
                        <div
                            key={index}
                            className={`h-2 w-2 rounded-full mr-1 bg-primary ${slideIndex === index ? '' : 'opacity-50'}`}
                            onClick={handleSlideIndexChange(index)}
                        />
                    ))}
                </div>
            )}
        </div>
    )
})

export default Slider
