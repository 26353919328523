import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../general/Card'
import PlaceholderProfile from '../../images/ui/PlaceholderProfile.svg'
import Img from 'gatsby-image'
import { truncate } from '../../functions/TextUtils'




// Sets the limit on the length of review in testimonials 
// Calculates chars
const REVIEW_LIMIT = 270
const MOBILE_REVIEW_LIMIT = 400




const ReviewCard = React.memo(props => {
    const {
        profilePhoto,
        name,
        companyName,
        logoIsWhite,
        review,
        companyLogo,
        className,
        style,
        mobile,
        onClick,
        limitSize,
        expanded,
        setExpanded
        // caller
    } = props;


    const [ reviewLength, setReviewLength ] = useState(mobile ? MOBILE_REVIEW_LIMIT : REVIEW_LIMIT)
    
    // Manage Company Logo - defaults to not showing if there isn't one
    const companyLogoSrc = companyLogo ? companyLogo.file.url : null
    const companyLogoAlt = companyLogo ? companyLogo.title  : null


    useEffect(() => {
        if (expanded) return setReviewLength(review.split("").length + 1)
        if (!expanded && review.split("").length < reviewLength && setExpanded) setExpanded(true)
        return () => {
            
        };
    }, [expanded, review, reviewLength, setExpanded])

    function handleClick() {
        if (mobile) return
        if (!onClick) return
        if (review.split("").length > reviewLength) {
            return setReviewLength(review.split("").length + 1)
        }
        return onClick()
    }

    return (
        <Card
            onClick={() => handleClick()}
            style={{ ...{ height: 'auto', width: '100%' }, ...style }}
            className={className + ` flex flex-col justify-between  transition-card px-6 py-5 ${mobile ? 'shadow-2xl' : 'hover:shadow-2xl'}`}
        >
            {companyLogo && companyLogoSrc && companyLogoAlt ?
                <div >
                    <img
                        className={`mx-auto my-2 w-20 ${logoIsWhite ? "blue-filtered" : ""} `}
                        src={companyLogoSrc} alt={companyLogoAlt} />

                </div> : ""}
            <div className="transition relative mt-4">
                <p className="text-lg transition">{ limitSize ? truncate(review, reviewLength) : review }</p>

                { limitSize && review.split("").length > reviewLength ? 
                <p className=" hidden md:block text-left text-sm hover:underline"
                >Read more</p> 

                : "" }
            </div>
            <div className="flex mt-4">

                { profilePhoto && profilePhoto.fixed ? 
                    <Img className="rounded-full" fixed={profilePhoto.fixed} alt={name + " Headshot"} />
                    : <img className="h-12 w-12 rounded-full" src={PlaceholderProfile}  alt="Placeholder Profile"/>
                }
                <h4 className="ml-4 text-lg leading-tight"><b>{name}</b> <br /> <span className="text-lg">{companyName}</span> </h4>
            </div>

        </Card>
    )
})

ReviewCard.defaultProps = {
    limitSize: false
}

ReviewCard.propTypes = {
    profilePhoto: PropTypes.object,
    name: PropTypes.string,
    companyName: PropTypes.string,
    review: PropTypes.string,
    companyLogo: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object,
    limitSize: PropTypes.bool
}

export default ReviewCard
