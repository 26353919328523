import React from 'react'
import PropTypes from 'prop-types'
import MiniCard from '../general/MiniCard';
import LogoPlaceholder from '../../images/ui/LogoPlaceholder.png'
import Img from 'gatsby-image'

const LogoCard = props => {

    const { companyLogo, mobile, ...restProps } = props;


    // Manage Company Logo - defaults to not showing if there isn't one
    // const companyLogoSrc = companyLogo ? companyLogo.file.url : LogoPlaceholder
    const companyLogoAlt = companyLogo ? companyLogo.title : "Logo Placeholder"



    const fluid = companyLogo ? companyLogo.fluid ? companyLogo.fluid : false : false
    // // Handles the different data formats 
    // // One coming from the client request (contentfulAPI)
    // // the other coming from the build time request
    const src = !companyLogo ? 
        LogoPlaceholder : fluid ?
        companyLogo.fluid.src :
        companyLogo.file.url

    return (
        <MiniCard
            {...restProps}
            className={`${props.className} flex transition-card ${mobile ? 'shadow-2xl' : 'hover:shadow-2xl'}`}
            type={props.type}
            arrow={false}
            style={{
                ...{
                    height: '100%', width: '100%',
                },
                ...props.style
            }}
        >
            { fluid ? <Img
                className={`mx-auto self-center`}
                fluid={fluid}
                alt={companyLogoAlt}
                style={{ width: mobile ? 120 : 120 }}
            /> :
            <img
                className={`mx-auto self-center`}
                src={src}
                alt={companyLogoAlt}
                style={{ maxHeight: 100, maxWidth: mobile ? 100 : 150 }}
            />
            }
        </MiniCard>
    )
}

LogoCard.propTypes = {
    companyLogo: PropTypes.object
}

export default LogoCard
