import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CardExpander from '../testimonials/CardExpander';


const TestimonialColumn = ({ testimonials, index, rowsToDisplay, style }) => {
    // console.log('Rendering - 2 - Each Column')
    // console.log('Rendering -> TestimonialColumn', props)

    const testimonialsToRender = useMemo(() => testimonials.length > 0 ? testimonials.map((edge, testIndex) => {
        // console.log('Rendering - 3 - Num Rows')

        // console.log(index)
        // Handle amount of rows to display
        if ((testIndex + 1) > rowsToDisplay) return ""
        const hasYellowBackground = (index + 1) % 2 === 0 ? ((testIndex + 2) % 2 === 0) : ((testIndex + 1) % 2 === 0);
        return (
            <CardExpander
                caller="TestCol"
                // If it is an even column check if it is odd or even on the row and show accordingly
                limitSize={true}
                flipped={false}
                className={`w-full my-1 ${hasYellowBackground ? 'bg-secondary' : ''}`}
                key={edge.id + Math.random()}
                companyName={edge.companyName}
                name={edge.name}
                logoIsWhite={edge.logoIsWhite}
                review={edge.review.review}
                companyLogo={edge.companyLogo}
                profilePhoto={edge.profilePhoto}
            />
        );
    }) : null, [testimonials, rowsToDisplay, index])

    return (
        <div 
        
            data-aos="fade-up" data-aos-duration="1000"
            className={`flex flex-col pb-16 md:w-1/3 lg:w-1/4 relative`}
            style={{
                // marginTop: getOffSet(index),
                ...style
            }}
        >
            {testimonialsToRender}

        </div>
    )
}

TestimonialColumn.propTypes = {
    testimonials: PropTypes.arrayOf(PropTypes.object),
    index: PropTypes.number,
    rowsToDisplay: PropTypes.number,
    bp: PropTypes.number,
    style: PropTypes.object
}

export default TestimonialColumn
