import React, { useState } from 'react'
import LogoCard from './LogoCard';
import ReviewCard from './ReviewCard';
import '../../css/custom/animation.css'


const CardExpander = React.memo(({ mobile, setHeight, ...restProps }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpanded = () => {
        if (!expanded && !mobile) {
            setExpanded(true);
        }
    }

    return (
        <div
            data-aos="fade-up" data-aos-duration="1000"
            className={`${mobile ? "" : "cursor-pointer"}`}
            style={{ ...restProps.style }}
        >
            <div style={{ width: mobile ? '100%' : '94%' }} className="mx-auto relative">
                <LogoCard
                    mobile={mobile}
                    className="logo-card absolute p-0 "
                    type="secondary"
                    companyLogo={restProps.companyLogo}
                    style={{ zIndex: -1 }}
                />
                <div onClick={handleExpanded}>
                    <ReviewCard setExpanded={setExpanded} expanded={expanded} mobile={mobile} {...restProps} />
                </div>
            </div>

        </div>
    )
})

export default CardExpander
