export function ExtractFirstName(fullName) {
    const nameRegex = /\b\S+/
    const firstName = fullName.match(nameRegex)
    if (firstName.length > 0) {
        return firstName[0]
    }
    return  fullName
}

export function truncate(text, lengthToDisplay) {
    const textArray = text.split("")
    const totalChars = textArray.length
    if (totalChars < lengthToDisplay ) {
        return text
    }

    if (totalChars && lengthToDisplay) {
        return text.split("").slice(0, lengthToDisplay).join("") + "..."
    } else {
        return "No text provided"
    }
}