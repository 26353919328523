import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby';
import { findBreakPoint } from '../../functions/Utils'


import LoadMore from '../general/LoadMore';
import TestimonialColumn from './TestimonialColumn';
import TestimonialRow from './TestimonialRow';
import ParallaxContainer from '../parallax/ParallaxContainer';



// Sets the number of rows of testimonials to display on desktop
const ROWS_TO_DISPLAY = 3

// Number to display limits
const MOBILE_LIMIT = 12
const DESKTOP_LIMIT = 24

const INITIAL_DISPLAYED = 12

// For Netlify's deployment errors
const activeWindow = typeof window !== "undefined" ? window : ''
const windowWidth = activeWindow ? activeWindow.innerWidth : 0;


const Testimonials = (props) => {
  const { className } = props;

  const { allContentfulOrderTestimonials } = useStaticQuery(graphql`
    query {
      allContentfulOrderTestimonials {
        edges {
          node {
            testimonials {
              id
              companyName
              logoIsWhite
              topLevel
              name
              profilePhoto {
                file {
                  url
                }
                fixed(width: 48, height: 48) {
                  ...GatsbyContentfulFixed_withWebp
                }
                description
              }
              review {
                review
              }
              companyLogo {
                file {
                  url
                }
                fluid(maxWidth: 200) {
                  ...GatsbyContentfulFluid_tracedSVG
                }
                title
              }
            }
          }
        }
      }
    }
  `);

  // For when loading more testimonials
  const [displayed, setDisplayed] = useState(INITIAL_DISPLAYED)
  const [displayRow, setDisplayRow] = useState(ROWS_TO_DISPLAY)
  const [columnsToCreate, setColumnsToCreate] = useState(findBreakPoint(windowWidth))
  const [ testExpandHeight, setTestExpandHeight] = useState("100vh")

  const testimonials = allContentfulOrderTestimonials.edges[0].node.testimonials;

  // Checking for the break point from 4 > 3 columns
  const checkWindowSize = () => {
    if (findBreakPoint(activeWindow.innerWidth) === columnsToCreate) return
    setColumnsToCreate(findBreakPoint(activeWindow.innerWidth))
    return
  }

  useEffect(() => {
    activeWindow.addEventListener('resize', checkWindowSize);
    return () => {
      window.removeEventListener('resize', checkWindowSize);
    };
  })


  const getTestimonials = (rowsToLoad, numColumns, testimonials, columnIndex) => {


    // Creates an an array of indexes to use to pull out the correct testimonials
    const rows = Array.from(Array(rowsToLoad).keys()).map(i => (((i + 1) * numColumns) - (numColumns - columnIndex)) - 1);

    const testimonialsToRender = rows.map((row) => {
      return testimonials[row];
    })



    return testimonialsToRender;

  }



  const orderTestimonials = useMemo(() => testimonials => {

    // Handle no testimonials
    if (!testimonials || testimonials.length < 1 || !columnsToCreate) return []


    // Limiting testimonial total
    const tLen = testimonials.length > DESKTOP_LIMIT ? DESKTOP_LIMIT : testimonials.length

    // Breakpoint (how many columns)
    const bp = columnsToCreate;

    // Full Rows of testimonials
    const totalFullRows = Math.floor(tLen / bp);

    // How many times to iterate
    const rowsToLoad = displayRow > totalFullRows ? totalFullRows : displayRow;

    // How many left over
    // const otherRow = tLen % bp

    // Create an iterator for the number of columns
    const columnIterator = Array.from(Array(bp).keys());
    // console.log(columnIterator)
    // Create columns with testimonials
    const columns = columnIterator.map(i => getTestimonials(rowsToLoad, bp, testimonials, (i + 1)))
    // console.log(columns)

    return columns
  }, [columnsToCreate, displayRow])

  const renderTestimonials = useMemo(() => orderTestimonials(testimonials).map((col, index) => (
    <TestimonialColumn
      bp={columnsToCreate}
      rowsToDisplay={displayRow}
      key={index}
      testimonials={col}
      index={index}
    />
  )), [testimonials, columnsToCreate, displayRow, orderTestimonials])

  const handleLoadMore = () => {
    if (testimonials.length > displayed) {
      setDisplayRow(displayRow + 1)
      setDisplayed(displayed + 4)
    }

  }

return (
    <>
      {/* Desktop (MD and up) */}
      <div
        data-aos="fade-up" data-aos-duration="1000"
        className={`${className} max-w-6xl hidden sm:flex mx-auto`}
      >

        {findBreakPoint(windowWidth) > 2 ?
          <ParallaxContainer
            weighting={0.3}
          >
            { renderTestimonials }
          </ParallaxContainer>
          : ""}




      </div >

      <div className={`${className} max-w-screen sm:hidden `}
        // style={{ height: testExpandHeight }}
        style={{minHeight: 800 }}
      >

        {/* Reduce amount of testimonials loaded */}
        {findBreakPoint(windowWidth) < 3 ?
          <TestimonialRow
            setHeight={setTestExpandHeight}
            currentHeight={testExpandHeight}

            // Limiting to top level 12
            testimonials={testimonials.slice(0, MOBILE_LIMIT)}
          /> : ""}
      </div >

      {testimonials && (
        <LoadMore
          id="h-seemoretestimonials"
          className="hidden md:block"
          text="See More Lovely Testimonials"
          handleLoadMore={handleLoadMore}
          loading={false}

          // Limiting the total displayed
          loadedAll={displayed >= testimonials.length}
        />
      )}
    </>
  )
}

Testimonials.propTypes = {
  className: PropTypes.string
}

export default Testimonials
