import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Card from './Card'
import Slider from './Slider'

const TestimonialCardTwo = () => {
    const { contentfulTestimonialCard } = useStaticQuery(graphql`
        query {
            contentfulTestimonialCard(contentful_id: { eq: "1gNxMhf7h0UQfixZLfgpD0" }) {
                title
                testimonials {
                    shortReview
                    companyLogo {
                        file {
                            url
                        }
                        title
                    }
                }
            }
        }
    `)

    return (
        <Card
            style={{ height: '300px', maxWidth: '400px' }}
            type="secondary"
            className="flex flex-col flex-shrink-0 w-95p md:w-full md:-ml-64 top-0 md:top-8 transition-quick absolute md:relative overflow-hidden -bottom-48 shadow-xl z-20"
        >
            <Slider>
                {contentfulTestimonialCard.testimonials.map(({ shortReview, companyLogo }) => {
                    const companyLogoSrc = companyLogo ? companyLogo.file.url : null
                    const companyLogoAlt = companyLogo ? companyLogo.title  : null

                    return (
                        <div className="flex flex-col items-center justify-between h-full">
                            <p className="text-xl text-center leading-6 my-auto pb-2 md:pb-8">{`“${shortReview}”`}</p>
                            {companyLogo && companyLogoSrc && companyLogoAlt && (
                                <img className="w-24" src={companyLogoSrc} alt={companyLogoAlt} />
                            )}
                        </div>
                    )
                })}
            </Slider>
        </Card>
    )
}

export default TestimonialCardTwo
