import React from 'react'
import PropTypes from 'prop-types'
import MobileTestimonials from './MobileTestimonials';

const TestimonialRow = props => {
    const { testimonials, ...restOfProps } = props;
    return (
        <div 
            className="flex-auto h-screen relative"
            id="carou"
        >
            <MobileTestimonials testimonials={testimonials} {...restOfProps}/>
        </div>
    )
}

TestimonialRow.propTypes = {
    testimonials: PropTypes.arrayOf(PropTypes.object)
}

export default TestimonialRow

