import React from 'react'
import PropTypes from 'prop-types'

const ThreeFeatures = ({ features }) => (
    <div className="flex max-w-5xl items-start mx-auto flex-col md:flex-row">
        {features.map(({ title, subTitle }) => (
            <div key={title} className="flex-grow text-center my-4 md:mx-8 box-border lg:w-full">
                <h2 className="uppercase border-b-2 border-secondary inline-block text-xl">{title}</h2>
                <p className="text-left mt-6">{subTitle}</p>
            </div>
        ))}
    </div>
)

ThreeFeatures.propTypes = {
    features: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            subTitle: PropTypes.string.isRequired,
        })
    ).isRequired
}

export default ThreeFeatures
