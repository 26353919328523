import React, { useContext, useState } from 'react'
import { navigate } from 'gatsby'

import Arrow from '../../images/ui/arrow-2.svg'
import ArrowWhite from '../../images/ui/arrow-2-white.svg'
import { BotContext } from '../context/BotContext'
import { NavContext } from '../context/NavContext'

const ServicesSection = () => {
    const { contentfulOrderServices: { description, services } } = useContext(NavContext)
    const { setActiveBot, setBotShow } = useContext(BotContext)
    const [hoveredService, setHoveredService] = useState(null)
    const servicesToColumns = Array(3)
        .fill()
        .map((_, index) => services.slice(index).filter((_, serviceIndex) => serviceIndex % 3 === 0));

    const handleBotActivation = (heading) => () => {
        if (heading === 'Office Design and Construction') {
            navigate('/services/office-design')
            return
        }
        if (heading === 'For Asset Owners') {
            navigate('/asset-owners');
            return
        }
        if (heading === 'Available Properties') {
            navigate('/available-properties');
            return
        }

        setActiveBot('ServicesLandBot')
        setBotShow(true)
    }

    const handleHoveredServiceChange = (updatedHovered) => () => setHoveredService(updatedHovered)
    return (
        <div data-aos="fade-up" data-aos-duration="1000" className="max-w-6xl mx-auto px-6 mb-24 mt-48">
            <h2 id="services-section-title" className="text-center -mt-48 pt-48">
                Need more than just a new office? <span className="border-b-2 border-secondary font-bold">Your one stop shop for everything workplace related</span>
            </h2>
            <p className="text-center my-8 md:mb-16">{description}</p>
            <div className="flex -mx-6 flex-col md:flex-row">
                {servicesToColumns.map((service) => (
                    <div style={{ flex: '1 0 calc(25% - 4rem)' }} className="flex m-4 md:m-8">
                        <div className="flex flex-col -m-4 md:-m-8">
                            {service.map(({ heading, description, icon }) => (
                                <div
                                    style={{
                                        transform: `translate3d(0, 0, 0) translateZ(0) ${hoveredService === heading ? 'scale(1.05)' : 'scale(1)'}`,
                                    }}
                                    className="duration-300 cursor-pointer transition-quick shadow-light hover:shadow-stronger p-6 m-4 md:m-8"
                                    tabIndex={0}
                                    onMouseEnter={handleHoveredServiceChange(heading)}
                                    onMouseLeave={handleHoveredServiceChange(null)}
                                >
                                    <div
                                        id={`hs-${heading.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}`}
                                        className="absolute top-0 left-0 w-full h-full transition-quick"
                                        style={{
                                            background: 'radial-gradient(circle at bottom right, #f9f9fa 20%, transparent 50%)',
                                            opacity: hoveredService === heading ? 1 : 0,
                                        }}
                                        onClick={handleBotActivation(heading)}
                                    >
                                        <img src={Arrow} alt="Button Arrow" className="absolute bottom-6 right-6" />
                                    </div>
                                    <div className="flex items-center">
                                        <img
                                            src={icon ? icon.file.url : null}
                                            alt={icon ? icon.title : ''}
                                            className="mr-4"
                                        />
                                        <h6 className="text-xl font-bold">{heading}</h6>
                                    </div>
                                    <p className="text-base leading-tight mt-4 mb-0">{description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="mt-16 text-center">
                <div
                    id="hs-findoutmore"
                    className="text-white bg-primary my-2 cursor-pointer py-3 text-md px-4 inline shadow-md hover:shadow-2xl transition-quick"
                    onClick={handleBotActivation()}
                >
                    Find out more about our services
                    <img src={ArrowWhite} alt="Button Arrow" className="inline pl-4" />
                </div>
            </div>
        </div>
    )
}

export default ServicesSection
