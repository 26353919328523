import React from 'react'
import PropTypes from 'prop-types'
import Arrow from '../../images/ui/arrow-2.svg'
import ArrowWhite from '../../images/ui/arrow-2-white.svg'

const MiniCard = props => {

    const { arrow, children, type, style, className, ...restProps } = props;


    const renderArrow = (arrow) => {
        if (!arrow) return ""
        return <img
            src={type === "primary" ? ArrowWhite : Arrow}
            alt="MiniCard Arrow"
            className="inline pl-4"
        />
    }


    // MiniCard Class
    const typeClasses = type === "primary" ? "bg-primary text-white border-2 border-secondary" : "bg-secondary text-primary border-2 border-primary"
    const MiniCardClasses = "px-4 py-1 inline-block flex-auto flex-wrap justify-between tracking-tight text-base"

    return (
        <div
            {...restProps}            
            className={`${typeClasses} ${MiniCardClasses} ${className}`}
            style={style}
        >
            {children}
            {renderArrow(arrow)}
        </div>)
}

MiniCard.defaultProps = {
    arrow: true,
    type: "primary"
}

MiniCard.propTypes = {
    arrow: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    type: PropTypes.oneOf(["primary", "secondary"]),
    className: PropTypes.string,
    style: PropTypes.object,
}

export default MiniCard
