import React, { useRef } from 'react'
import clamp from 'lodash-es/clamp'
import { useSprings, animated } from 'react-spring'
import { useGesture } from 'react-with-gesture'
import CardExpander from '../testimonials/CardExpander'


// For Netlify's deployment errors
const activeWindow = typeof window !== "undefined" ? window : ''

const MobileTestimonials = React.memo(props => {


    const { testimonials, ...restProps } = props;


    const index = useRef(0)
    const myRef = useRef(null)

    // console.log('Rendering -> MobileTestimonials', restProps)


    const [springs, set] = useSprings(testimonials.length, i => ({ x: (i * activeWindow.innerWidth), scale: 1, display: 'block', left: i === 0 ? '10%' : "2.5%", right: '0%', top: i === 0 ? '5%' : "8%", current: 0.9 }))


    const bind = useGesture(({ down, delta: [xDelta], direction: [xDir], distance, cancel }) => {
        if (down && distance > (activeWindow.innerWidth / 5) * 2)
            cancel((index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, testimonials.length - 1)))


        set(i => {

            if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }


            const x = (i - index.current) * (activeWindow.innerWidth) + (down ? xDelta : 0)
            // console.log('down', down, 'distance', distance, 'activeWindow', activeWindow.innerWidth)
            const scale = down ? 1 - distance / activeWindow.innerWidth / 2 : 1


            const direction = index.current < i ? { left: '2.5%', top: '10%' } :
                index.current > i ? { left: "17.5%", top: '10%' } :
                    index.current === i ? { left: '10%', top: '5%' } : {}

            return { x, scale, display: 'block', ...direction }


        })
    })

    return springs.map(({ x, display, scale, left, right, top }, i) => (
            <animated.div
                key={i}
                ref={myRef}
                {...bind()}
                onClick={() => console.log(myRef)}
                className="sm:w-3/4"
                style={{
                    position: 'absolute',
                    width: '90%',
                    height: '100%',
                    flex: 1, // added
                    willChange: 'transform',
                    margin: '0px -5%',
                    left, right, top, display, transform: x.interpolate(x => `translate3d(${x}px,0,0)`)
                }}
            >
                <animated.div
                    style={{
                        width: '100%', willChange: 'transform', transform: scale.interpolate(s => `scale(${s})`),

                        // display: 'flex', 
                        // height: '100%'
                    }} >

                    <CardExpander
                        {...restProps}
                        mobile={true}
                        caller="MobileTest"
                        limitSize={true}

                        // If it is an even column check if it is odd or even on the row and show accordingly
                        // flipped={(index + 1) % 2 === 0}
                        // style={{ width: '94%' }}
                        className="w-full  mx-auto"
                        key={testimonials[i].id + Math.random()}
                        companyName={testimonials[i].companyName}
                        name={testimonials[i].name}
                        review={testimonials[i].review.review}
                        companyLogo={testimonials[i].companyLogo}
                        logoIsWhite={testimonials[i].logoIsWhite}
                        profilePhoto={testimonials[i].profilePhoto}

                    />
                </animated.div>
            </animated.div>
    ))
})
export default MobileTestimonials