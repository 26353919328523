import React, { useContext } from 'react'

import { BotContext } from '../context/BotContext'
import ArrowWhite from '../../images/ui/arrow-2-white.svg'
import London from '../../images/symbols/London.svg'
import Paris from '../../images/symbols/Paris.svg'
import Sydney from '../../images/symbols/Sydney.svg'
import Barcelona from '../../images/symbols/Barcelona.svg'
import NewYork from '../../images/symbols/NewYork.svg'

const LocationsSection = () => {
    const { setActiveBot, setBotShow } = useContext(BotContext)

    const handleBotActivation = () => {
        setActiveBot('LocationsLandBot')
        setBotShow(true)
    }

    return (
        <div data-aos="fade-up" data-aos-duration="1000" className="max-w-6xl mx-auto px-6 mb-24 mt-48">
            <div className="flex justify-evenly mx-auto mb-16">
                <img src={London} style={{ maxWidth: '5rem' }} className="w-1/6 md:w-full" alt="London Elizabeth Tower" />
                <img src={Paris} style={{ maxWidth: '5rem' }} className="w-1/6 md:w-full" alt="Paris Eiffel Tower" />
                <img src={Sydney} style={{ maxWidth: '5rem' }} className="w-1/6 md:w-full" alt="Sydney Opera House" />
                <img src={Barcelona} style={{ maxWidth: '5rem' }} className="w-1/6 md:w-full" alt="Barcelona Cathedral" />
                <img src={NewYork} style={{ maxWidth: '5rem' }} className="w-1/6 md:w-full" alt="New-York Statue of Liberty" />
            </div>
            <h2 className="text-center">
                Going global? We’ve been there! <span className="border-b-2 border-secondary font-bold">Finding offices all over the world</span>
            </h2>
            <p className="text-center my-8 md:mb-24">
                From London to Los Angeles, Singapore to San Francisco, New York to...you get the gist.
                Wherever you’re looking, we cover the whole market.
            </p>
            <div className="flex flex-col md:flex-row md:-m-8">
                <div className="my-4 w-full md:w-1/3 md:m-8">
                    <h4 className="text-xl inline-block mb-4 uppercase">
                        <span className="border-b-2 border-primary font-bold">HYBRID OFFICES</span>
                        <br />
                        FOR A MIX OF IN-HOUSE AND REMOTE
                    </h4>
                    <p className="leading-relaxed">
                        The option of having employees meet for some of the time and WFH the rest? At a fair price?
                        And in a way that works, rather than the horrors of hotdesking? Yes, it can be done.
                        Talk to us to find out how a hybrid office could tick the boxes for you.
                    </p>
                </div>
                <div className="m-4 w-full md:w-1/3 md:m-8">
                    <h4 className="text-xl inline-block mb-4 uppercase">
                        <span className="border-b-2 border-primary font-bold">Fully-managed</span>
                        <br /> Flexible offices
                    </h4>
                    <p className="leading-relaxed"> Minimal fuss. <br />
                        Fast turnaround. <br />
                        Plug ‘n’ play desks. <br />
                        <br />
                        (Our service is completely free for you because we get paid by the office operator. Not bad, right?)
                    </p>
                </div>
                <div className="m-4 w-full md:w-1/3 md:m-8">
                    <h4 className="text-xl inline-block mb-4 uppercase">
                        <span className="border-b-2 border-primary font-bold ">LEASED OFFICES</span>
                        <br />FROM SHORT TO LONG TERM
                    </h4>
                    <p className="leading-relaxed">Put down roots. <br />
                        Stay a while.<br />
                        Have things your way.<br />
                        <br />
                        (In this case our service is either free or comes at a small % fee. We’re not always the cheapest, but there’s a reason for that. Why not check out our <a className="hover:text-secondary underline" href="https://www.google.co.uk/search?ei=a0jNXZq9F8TKgQa23qbQDw&q=kontor&oq=kontor&gs_l=psy-ab.3..0l10.5390.7200..7555...2.4..0.153.663.7j1#lrd=0x48761cb69c43afe7:0x34d62d870292bdf9,1,,," target="_blank" rel="noopener noreferrer" aria-label='GoogleReviews'>Google reviews</a>?)
                    </p>
                </div>
            </div>
            <div className="mt-16 text-center">
                <div
                    id="h-findoutmore"
                    className="text-white bg-primary inline-block my-2 cursor-pointer py-3 text-md px-4 shadow-md hover:shadow-2xl transition-quick"
                    onClick={handleBotActivation}
                    tabIndex={0}
                >
                    Find out more about the locations we cover
                    <img src={ArrowWhite} alt="Button Arrow" className="inline pl-4" />
                </div>
            </div>
        </div>
  )
}

export default LocationsSection
